import { render, staticRenderFns } from "./likes.vue?vue&type=template&id=e3ee1ccc&scoped=true&"
import script from "./likes.vue?vue&type=script&lang=ts&"
export * from "./likes.vue?vue&type=script&lang=ts&"
import style0 from "./likes.vue?vue&type=style&index=0&id=e3ee1ccc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3ee1ccc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VSkeletonLoader})
