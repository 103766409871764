





























import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { LikesViewModel } from '../viewmodels/likes-viewmodel'

@Observer
@Component({
  components: {
    'post-list': () => import('./components/likes/post-list.vue'),
    'post-grid': () => import('./components/likes/post-grid.vue'),
  },
})
export default class Likes extends Vue {
  @Provide() vm = new LikesViewModel()

  @Watch('$route', { immediate: true }) tabChange(val) {
    const hash = this.$router.currentRoute.hash
    if (hash) {
      if (hash === '#like') {
        setTimeout(() => {
          document.getElementById(`list-like`)?.scrollIntoView({ behavior: 'smooth' })
        }, 300)
      }
    }
  }

  destroyed() {
    this.vm.destroy()
  }
}
