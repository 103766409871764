import { appProvider } from '@/app-provider'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { ProfileModel } from '@/models/profile-model'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { postController } from '@/modules/common/dialogs/post/post-controller'
import { apiService } from '@/services/api-services'
import { PostStore } from '@/stores/post-store'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class LikesViewModel {
  @observable posts?: PostStore[]
  @observable fetching = false
  @observable likedLoadingState = { _limit: 9, _start: 0 }
  @observable totalPosts = 0
  @observable loadingMoreLikedPost = false
  @observable userProfile?: ProfileModel
  @observable display: 'grid' | 'list' = 'list'
  private _disposers: IReactionDisposer[]

  constructor() {
    this.init()

    this._disposers = [
      reaction(
        () => createPostController.completedUpdateType,
        (type) => {
          if (type) {
            createPostController.changeCompletedUpdateType(undefined)
            this.fetchLikedPost(this.userProfile?._id)
          }
        }
      ),
      reaction(
        () => postController.completeUpdateType,
        (type) => {
          if (type) {
            postController.changeCompleteUpdateType(undefined)
            this.fetchLikedPost(this.userProfile?._id)
          }
        }
      ),
    ]
  }

  @action destroy() {
    this._disposers.map((d) => d())
    this.posts?.forEach((postStore) => postStore.destroy())
  }

  @asyncAction *init() {
    try {
      this.fetching = true
      const userId = appProvider.router.currentRoute.params.userId
      const userProfiles = yield apiService.profiles.find({ unique_id: userId, _limit: 1 })
      if (userProfiles[0]) {
        this.userProfile = userProfiles[0]
        yield this.fetchLikedPost(this.userProfile?._id)
      } else {
        snackController.commonError('Can not find user profile')
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.fetching = false
    }
  }

  @asyncAction *fetchLikedPost(profile) {
    try {
      const likes = yield apiService.likes.getLikedPost({ ...this.likedLoadingState, profile, _sort: 'createdAt:desc' })
      this.totalPosts = yield apiService.likes.count({ profile })
      this.posts = likes.map((like: any) => {
        const post = like.post
        const postStore = new PostStore(post)
        postStore.post.isLike = true
        return postStore
      })
      return likes
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *loadMoreLikedPost() {
    try {
      this.loadingMoreLikedPost = true
      this.likedLoadingState = {
        ...this.likedLoadingState,
        _start: this.likedLoadingState._start + this.likedLoadingState._limit,
      }
      const postMore = yield apiService.likes.getLikedPost({
        ...this.likedLoadingState,
        profile: this.userProfile?._id,
      })
      const newPost = postMore.map((like: any) => {
        const post = like.post
        const postStore = new PostStore(post)
        postStore.post.isLike = true
        return postStore
      })
      const currentPost = this.posts as PostStore[]
      if (currentPost) {
        this.posts = [...currentPost, ...newPost]
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.loadingMoreLikedPost = false
    }
  }

  @action.bound changeDisplay(value: 'list' | 'grid') {
    this.display = value
  }

  @computed get canLoadMoreLikedPost() {
    if (this.posts && this.posts.length < this.totalPosts) return true
    else return false
  }
}
